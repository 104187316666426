/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import ReactHtmlParser from 'react-html-parser'

import '../sass/main.sass'

function SEO({ 
	description,
	lang,
	meta,
	title,
	image
}) {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					author
					image
				}
			}
		}
	`)

	const metaDescription = description || site.siteMetadata.description
	const metaImage = image || site.siteMetadata.image

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={ReactHtmlParser(title)[0]}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			link={[
				{
					rel: 'stylesheet',
					href: 'https://use.typekit.net/lbu8odj.css'
				}
			]}
			meta={[
				{
					name: 'viewport',
					content: 'width=device-width',
				},
				{
					name: "google-site-verification",
					content: "qeHssvaFS582GddawE0LqAeMj-nkhsBgYO34X70Awr0"
				},
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:image`,
					content: metaImage,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		/>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

export default SEO
